<template>
	<div class="notice_popup">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close');"/>
		</div>
		<div class="tit">담당자 승인하기</div>
		<div class="member"><span class="name">{{forwardData.mberNm}} ({{forwardData.loginId}}) 님을</span><br/>{{forwardData.corpNm}}의 담당자로 승인하시겠습니까?</div>
		<!-- <div class="member"><span class="name">'{{corpNm}}'을</span><br/>공급기업으로 승인하시겠습니까?</div> -->
		<div class="select-btn">
			<div class="btn" @click="$emit('close');">취소</div>
			<div class="btn" @click="$emit('close', true);">예</div>  
		</div>
	</div>
</template>

<script>
export default {
	props:['param'],
	data() {
		return {
			forwardData : this.param
		}
	}
}
</script>